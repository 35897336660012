import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  BooleanField,
  DateTimeField,
  EmailField,
  NumberField,
  SelectField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  BooleanProperty,
  DateTimeProperty,
  EmailProperty,
  NumberProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class User extends CrudModel {
  protected static _typeLabel = "User";
  protected static asProperty = "user";
  public static api = {
    path: "users",
  };

  protected userIdField = ["id"];
  protected static routeBase = "users";

  public static modalEdit = true;

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.Read,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_admin",
        label: "Admin Account",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "zoho_connected",
        label: "Connected to Zoho",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "enable_text_notifications",
        label: "Receive Text Notifications",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "first_name",
        label: "First Name",
        sortable: true,
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "last_name",
        label: "Last Name",
        sortable: true,
        rules: ["required"],
      },
    },
    {
      type: EmailProperty,
      opts: {
        name: "email",
        label: "Email",
        sortable: true,
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "phone",
        label: "Phone",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "password",
        label: "Password (leave blank to ignore)",
        sortable: false,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "start_screen",
        label: "Start Screen",
        sortable: false,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "zoho_calendar_id",
        label: "Zoho Calendar ID",
        sortable: false,
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
            [UserRole.EntityOwner]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "calls",
        label: "Calls",
        relatedModel: "Call",
        foreignProperty: "users",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "events",
        label: "Events",
        relatedModel: "Event",
        foreignProperty: "user",
        userPermissions: UserPermission.Hidden,
      },
    },
  ];
  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    { type: NumberField, opts: { property: "legacy_id" } },
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: BooleanField, opts: { property: "is_admin" } },
    { type: BooleanField, opts: { property: "enable_text_notifications" } },
    { type: BooleanField, opts: { property: "zoho_connected" } },
    {
      type: StringField,
      opts: { property: "first_name", isPrimaryLabelField: 1 },
    },
    {
      type: StringField,
      opts: { property: "last_name", isPrimaryLabelField: 2 },
    },
    { type: EmailField, opts: { property: "email" } },
    { type: "PhoneField", opts: { property: "phone" } },
    { type: StringField, opts: { property: "password" } },
    {
      type: StringField,
      opts: {
        property: "zoho_calendar_id",
        formComponentName: "zoho-calendar-field",
        viewComponentName: "zoho-calendar-field",
      },
    },
    {
      type: SelectField,
      opts: {
        property: "start_screen",
        options: {
          accounts: "Accounts",
          "balances-due": "Balances Due",
          calls: "Calls",
          contacts: "Contacts",
          contracts: "Contracts",
          events: "Events",
          expenses: "Expenses",
          invoices: "Invoices",
          "jobs-schedule": "Jobs Schedule",
          "jobs-on-map": "Jobs on Map",
          metrics: "Metrics",
          payments: "Payments ",
          users: "Users",
        },
      },
    },
    // {
    //   type: RelationshipFieldMany,
    //   opts: {
    //     property: "calls",
    //     viewComponentName: "form-relationship-field-many-table",
    //     formComponentName: "form-relationship-field-many-table"
    //   }
    // }
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["first_name", "last_name", "email"],
      useModal: true,
      defaultSort: [
        {
          field: "last_name",
          order: "asc",
        },
      ],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: [
            "first_name",
            "last_name",
            "email",
            "phone",
            "password",
            "start_screen",
            "enable_text_notifications",
          ],
        },
        {
          id: "meta",
          fields: ["is_admin", "created_at", "updated_at", "zoho_connected"],
        },
      ],
    },
  ];
}
