
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      clipped: false,
      drawer: true,
      fixed: false,
      accountLoading: false,
      accountMenuItems: [
        {
          icon: "mdi-account-circle",
          title: "My Account",
          to: "/my-account",
          // to: "/users/{current_user}"
        },
        {
          icon: "mdi-exit-to-app",
          title: "Logout",
          click: function () {
            this.accountLoading = true;
            this.$auth.logout().then(() => {
              this.accountLoading = false;
              this.$router.push({
                path: "/login",
              });
            });
          }.bind(this),
        },
        {
          icon: "mdi-bug",
          title: "Toggle Debug",
          click: function () {
            // add debug query to current url
            let url = new URL(window.location.href);
            url.searchParams.set("debug", this.$config.debug ? "0" : "1");
            location.href = url.href;
          }.bind(this),
          adminOnly: true,
        },
      ],
      allPrimaryMenuItems: [
        {
          icon: "mdi-format-list-numbered",
          title: "Jobs Schedule",
          to: "/jobs-schedule",
        },
        {
          icon: "mdi-calendar",
          title: "My Appointments",
          to: "/events?user={current_user}",
        },
        {
          icon: "mdi-map",
          title: "Jobs on Map",
          to: "/jobs-on-map",
        },
        {
          icon: "mdi-receipt",
          title: "Balances Due",
          to: "/balances-due",
        },
        {
          icon: "mdi-chart-bar",
          title: "Metrics",
          to: "/metrics",
          adminOnly: true,
        },
        {
          icon: "mdi-file-document-edit-outline",
          title: "Completed Jobs",
          to: "/completed-jobs",
        },
      ],
      allSecondaryMenuItems: [
        {
          icon: "mdi-domain",
          title: "Accounts",
          to: "/accounts",
        },
        {
          icon: "mdi-account",
          title: "Contacts",
          to: "/contacts",
        },
        {
          icon: "mdi-calendar",
          title: "Appointments",
          to: "/events",
          adminOnly: true,
        },
        {
          icon: "mdi-phone",
          title: "Calls",
          to: "/calls",
        },
        {
          icon: "mdi-file-document-edit-outline",
          title: "Contracts",
          to: "/contracts",
        },
        {
          icon: "mdi-currency-usd",
          title: "Expenses",
          to: "/expenses",
          adminOnly: true,
        },
        {
          icon: "mdi-receipt",
          title: "Invoices",
          to: "/invoices",
          adminOnly: true,
        },
        {
          icon: "mdi-credit-card-outline",
          title: "Payments ",
          to: "/payments",
          adminOnly: true,
        },
        {
          icon: "mdi-account-multiple",
          title: "Users",
          to: "/users",
          adminOnly: true,
        },
        {
          icon: "mdi-message-badge-outline",
          title: "Notifications",
          to: "/notification-overview",
          adminOnly: true,
        },
      ],
      miniVariant: false,
    };
  },
  mounted() {
    if (typeof this.$route.query.debug !== "undefined") {
      this.$config.debug = this.$route.query.debug == "1" ? true : false;
    }
  },
  computed: {
    ...mapGetters("app", ["title"]),
    activePrimaryMenuItems() {
      return this.allPrimaryMenuItems.filter(
        (item) => !item.adminOnly || this.$auth.user.is_admin
      );
    },
    activeSecondaryMenuItems() {
      return this.allSecondaryMenuItems.filter(
        (item) => !item.adminOnly || this.$auth.user.is_admin
      );
    },
    activeAccountMenuItems() {
      return this.accountMenuItems.filter(
        (item) => !item.adminOnly || this.$auth.user.is_admin
      );
    },
    userName() {
      if (!this.$auth.loggedIn) return "";
      return this.$auth.user.first_name;
    },
  },
  methods: {
    decorateTo(to) {
      if (to && to.indexOf("{current_user}") !== -1) {
        to = to.replace("{current_user}", this.$auth.user.id);
      }
      return to;
    },
  },
};
