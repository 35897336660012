import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions
} from "../plugins/Cruxtify/UserPermission";
import {
  BooleanField,
  DateTimeField,
  RelationshipField,
  StringField
} from "../plugins/Cruxtify/field-types";
import {
  BooleanProperty,
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty
} from "../plugins/Cruxtify/property-types";

export class Notification extends CrudModel {
  protected static _typeLabel = "Notification";
  protected static asProperty = "notification";
  public static api = {
    path: "notifications"
  };
  protected static routeBase = "notifications";
  protected userIdField = [];
  protected static userPermissions = new UserPermissions(UserPermission.Read);

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Created At"
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Updated At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "send_at",
        label: "Send Time",
        userPermissions: UserPermission.Read
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "processed_at",
        label: "Processed Time",
        userPermissions: UserPermission.Read
      }
    },
    // {
    //   type: ComputedProperty,
    //   opts: {
    //     name: "sent",
    //     label: "Notification Sent",
    //     presentationProxy: BooleanProperty,
    //     computeValue: (notification: Notification) => {
    //       return (
    //         notification.getProperty("send_at").typedValue > Date.now() &&
    //         (notification.getProperty("text").value ||
    //           notification.getProperty("text").value)
    //       );
    //     }
    //   }
    // },
    {
      type: BooleanProperty,
      opts: {
        name: "text",
        label: "Texted"
      }
    },
    {
      type: BooleanProperty,
      opts: {
        name: "email",
        label: "Emailed"
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "text_error",
        label: "Text Error"
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "email_error",
        label: "Email Error"
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contact",
        relatedModel: "Contact"
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contract",
        relatedModel: "Contract"
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "invoice",
        relatedModel: "Invoice"
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "event",
        relatedModel: "Event"
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "notification_type",
        label: "Notification Type",
        relatedModel: "NotificationType"
      }
    }
  ];

  protected static fieldDefinitions = [
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "send_at" } },
    // { type: DateTimeField, opts: { property: "processed_at" } },
    // { type: BooleanField, opts: { property: "sent" } },
    { type: BooleanField, opts: { property: "text" } },
    { type: BooleanField, opts: { property: "email" } },
    { type: StringField, opts: { property: "email_error" } },
    { type: StringField, opts: { property: "text_error" } },
    { type: RelationshipField, opts: { property: "contact" } },
    { type: RelationshipField, opts: { property: "contract" } },
    { type: RelationshipField, opts: { property: "invoice" } },
    { type: RelationshipField, opts: { property: "event" } },
    { type: RelationshipField, opts: { property: "notification_type" } }
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform"
    }
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "asRelationship",
      useModal: true,
      filterFields: ["contact", "notification_type"],
      columnFields: [
        // "sent",
        "created_at",
        "notification_type",
        "contact",
        "text",
        "email"
      ],
      defaultSort: [
        {
          field: "created_at",
          order: "desc"
        }
      ]
    }
  ];
}
