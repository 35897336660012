import { CrudFilter } from "../plugins/Cruxtify/CrudFilter";

export class AddressFilter extends CrudFilter {
  public get componentName() {
    return "AddressFilter";
  }

  public queryLat: number | null = null;
  public queryLng: number | null = null;

  public get query() {
    return {
      address_lat: this.queryLat,
      address_lng: this.queryLng,
    };
  }

  public get componentOpts() {
    return { filter: this };
  }

  public clear() {
    this.queryLng = null;
    this.queryLat = null;
  }
}
