import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  MultipleFileField,
  NumberField,
  RelationshipField,
  StringField
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  MultipleFileProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty
} from "../plugins/Cruxtify/property-types";

export class Attachment extends CrudModel {
  protected static _typeLabel = "Attachment";
  protected static asProperty = "attachment";
  public static api = {
    path: "attachments"
  };

  protected static routeBase = "files";
  public static modalEdit = true;
  protected userIdProperty = ["author"];
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New
    },
    UserPermission.Read
  );
  protected userIdField = ["author"];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Created At",
        userPermissions: UserPermission.Read,
        computed: true
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
        computed: true
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "name",
        label: "Label",
        isPrimaryLabelProperty: true,
        userPermissions: UserPermission.Edit,
        sortable: true,
        rules: ["required"]
      }
    },
    {
      type: MultipleFileProperty,
      opts: {
        name: "files",
        apiUploadProperty: "attachment_upload_files",
        label: "Files",
        basenameProperty: "basename",
        fileProperty: "url",
        relatedModel: "AttachmentFile",
        foreignProperty: "attachment",
        fieldWidth: "full",
        userPermissions: UserPermission.Edit
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "account",
        label: "Account",
        relatedModel: "Account",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit
          },
          UserPermission.New
        )
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contact",
        label: "Contact",
        relatedModel: "Contact",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit
          },
          UserPermission.New
        )
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contract",
        label: "Contract",
        relatedModel: "Contract",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit
          },
          UserPermission.New
        )
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "author",
        label: "Uploaded By",
        relatedModel: "User",
        userPermissions: UserPermission.Read,
        computed: true
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "last_mod_author",
        label: "Last Modified By",
        relatedModel: "User",
        userPermissions: UserPermission.Read,
        computed: true
      }
    }
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: StringField, opts: { property: "name" } },
    { type: MultipleFileField, opts: { property: "files" } },
    { type: RelationshipField, opts: { property: "account" } },
    { type: RelationshipField, opts: { property: "contact" } },
    { type: RelationshipField, opts: { property: "contract" } },
    { type: RelationshipField, opts: { property: "author" } },
    { type: RelationshipField, opts: { property: "last_mod_author" } }
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "asRelationship",
      columnFields: ["name", "files", "created_at"]
    }
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: ["name", "files"]
        },
        {
          id: "1",
          fields: [
            "contact",
            "contract",
            "account",
            "author",
            "created_at",
            "last_mod_author"
          ]
        }
      ]
    }
  ];
}
